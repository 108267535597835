var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0 ma-0 text-center" }, [
    !_vm.hideName
      ? _c(
          "span",
          {
            staticClass:
              "text-h6 d-flex text-center align-center justify-space-between",
            class: `${_vm.textColor}--text`,
          },
          [
            _c("div", [_vm._t("btnPrepend")], 2),
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("Capitalize")(
                    _vm.visible_name ? _vm.visible_name : _vm.image_name
                  )
                ) +
                " "
            ),
            _c("div", [_vm._t("btnAppend")], 2),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        ref: "widthGet",
        staticClass: "col mx-auto d-flex flex-row align-center",
        staticStyle: { "user-select": "none" },
        style: {
          width: _vm.previewWidth,
          height: _vm.previewHeight,
          borderImage: _vm.borderGradient,
          background: _vm.backgroundGradient,
          borderWidth: _vm.previewBorderWidth,
        },
        attrs: { id: "bonusPreview" },
      },
      [
        _c("img", {
          staticClass: "colorMask",
          style: `background-image: url(${_vm.getBackgroundImage}); background-position: left center; mask-position:left center; mask-image: url(${_vm.getBackgroundImage}); background-color: ${_vm.iconTint}; height: 80%; width: 100%; max-width: 20%`,
        }),
        _vm.isTrueSetting
          ? _c(
              "div",
              {
                ref: "jTextDiv",
                staticClass: "jackpotText",
                style: {
                  background: _vm.textGradient + "border-box",
                  lineHeight: _vm.lineHeight,
                  fontSize: `${Math.min(
                    _vm.height,
                    _vm.width / (_vm.valueTextString.length / 2)
                  )}px`,
                },
              },
              [_vm._v(" " + _vm._s(_vm.valueTextString) + " ")]
            )
          : _vm._e(),
        _vm.borderColors?.length > 1
          ? _c(
              "div",
              {
                staticClass: "jackpotCurrency",
                style: `color: ${
                  _vm.borderColors[Math.floor(_vm.borderColors.length / 2)]
                }; font-size: ${6.5 / _vm.valueCurrency.length}vh`,
              },
              [_vm._v(" " + _vm._s(_vm.valueCurrency) + " ")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }