<template>
	<v-container class="pa-0 ma-0 text-center">
		<span
			v-if="!hideName"
			class="text-h6 d-flex text-center align-center justify-space-between"
			:class="`${textColor}--text`">
			<div><slot name="btnPrepend"></slot></div>
			{{ visible_name ? visible_name : image_name | Capitalize }}
			<div><slot name="btnAppend"></slot></div>
		</span>

		<div
			style="user-select: none"
			ref="widthGet"
			id="bonusPreview"
			v-bind:style="{
				width: previewWidth,
				height: previewHeight,
				borderImage: borderGradient,
				background: backgroundGradient,
				borderWidth: previewBorderWidth,
			}"
			class="col mx-auto d-flex flex-row align-center">
			<img
				class="colorMask"
				:style="`background-image: url(${getBackgroundImage}); background-position: left center; mask-position:left center; mask-image: url(${getBackgroundImage}); background-color: ${iconTint}; height: 80%; width: 100%; max-width: 20%`" />
			<div
				v-if="isTrueSetting"
				ref="jTextDiv"
				v-bind:style="{
					background: textGradient + 'border-box',
					lineHeight: lineHeight,
					fontSize: `${Math.min(
						height,
						width / (valueTextString.length / 2)
					)}px`,
				}"
				class="jackpotText">
				{{ valueTextString }}
			</div>
			<div
				:style="`color: ${
					borderColors[Math.floor(borderColors.length / 2)]
				}; font-size: ${6.5 / valueCurrency.length}vh`"
				v-if="borderColors?.length > 1"
				class="jackpotCurrency">
				{{ valueCurrency }}
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		backgroundColors: Array,
		backgroundColorStops: Array,
		textColors: Array,
		textColorStops: Array,
		borderColors: Array,
		borderColorStops: Array,
		iconUrl: String,
		iconTint: String,
		visible_name: String,
		hideName: {
			type: Boolean,
			default: false,
		},
		image_name: {
			type: String,
			default: "",
		},
		previewWidth: {
			type: String,
			default: "15vw",
		},
		previewHeight: {
			type: String,
			default: "15vh",
		},
		previewBorderWidth: {
			type: String,
			default: "10px",
		},
		textColor: {
			type: String,
			default: "kajot-text",
		},
		valueText: {
			type: Number,
			default: 123.45,
		},
		valueCurrency: {
			type: String,
			default: "EUR",
		},
	},

	data() {
		return {
			width: "6vh",
			height: "6vh",
		};
	},

	methods: {
		getWidth() {
			this.width = this.$refs.widthGet?.offsetWidth * 0.6 || 444;
			this.height = this.$refs.widthGet?.offsetHeight || this.vh(7);
		},
		vh(percent) {
			var h = Math.max(
				document.documentElement.clientHeight,
				window.innerHeight || 0
			);
			return (percent * h) / 100;
		},
		createCssGradient(colorArr, colorStopsArr) {
			let tmpBorderGradient = "";
			let tmpLength = colorArr.length;
			if (tmpLength === 1)
				return `linear-gradient(to bottom, ${colorArr[0]}, ${colorArr[0]})`;
			for (let i = 0; i < tmpLength; i++) {
				tmpBorderGradient += colorArr[i] + " " + colorStopsArr[i] + "% ";
				if (i + 1 < tmpLength) {
					tmpBorderGradient += ", ";
				}
			}
			return " linear-gradient(to bottom, " + tmpBorderGradient + ")";
		},
	},

	mounted() {
		this.getWidth();
		window.addEventListener("resize", this.getWidth);
	},

	created() {
		this.getWidth();
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.getWidth);
	},

	computed: {
		...mapGetters(['gamesUrl']),
		isTrueSetting() {
			return this.borderColors && this.borderColors.length > 0;
		},
		lineHeight() {
			return 0.7;
		},
		valueTextString() {
			return this.$options.filters
				.Number(this.valueText, { minor_units: 2, style: "decimal" })
				.replace(",", ".")
				.replaceAll("0", "O");
		},
		backgroundGradient() {
			if (this.backgroundColors && this.backgroundColorStops) {
				return this.createCssGradient(
					this.backgroundColors,
					this.backgroundColorStops
				);
			}
			return undefined;
		},
		textGradient() {
			if (this.textColors && this.textColorStops) {
				return this.createCssGradient(this.textColors, this.textColorStops);
			}
			return undefined;
		},
		getBackgroundImage() {
			if (!this.iconUrl) return "";
			try {
				return require(`@/assets/bonusCards/${this.iconUrl}`);
			} catch (error) {
				return `${this.gamesUrl}/config/joker_bonus/${this.iconUrl}`;
			}
		},
		borderGradient() {
			if (this.borderColors && this.borderColorStops) {
				return `${this.createCssGradient(
					this.borderColors,
					this.borderColorStops
				)} 10% 10%`;
			}
			return undefined;
		},
	},
};
</script>

<style lang="sass" scoped>

.jackpotText
	position: absolute
	right: 1.5vw
	font-family: 'Ubuntu Mono', monospace
	-webkit-background-clip: text !important
	-webkit-text-fill-color: transparent !important

.jackpotCurrency
	position: absolute
	right: 0.5vw
	writing-mode: vertical-rl
	text-orientation: upright
	font-family: 'Ubuntu Mono', monospace
	font-weight: 500
	line-height: 0.5vh
	letter-spacing: -0.6vh
	margin-top: -4px

.colorMask
	position: absolute
	background-size: contain !important
	mask-size: contain
	background-repeat: no-repeat !important
	mask-repeat: no-repeat !important
	background-blend-mode: multiply
	left: 7px

#bonusPreview2
	width: 200px
	height: 100px
	border-style: solid
	border-width: 100px

#bonusPreview
	position: relative
	border-style: solid
	padding: 0
</style>
